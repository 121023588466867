<script>
import FilterModal from './FilterModal.vue'
import { logEvent, events } from '@/services/analytics'
import { removeSpecialCharacters } from '@/utils/string'
import { mapGetters } from 'vuex'
import { CROP } from '@/utils/crops'

export default {
  components: {
    FilterModal,
  },

  props: {
    varieties: { type: Array, required: true },
  },

  data() {
    return {
      sortedAndFilteredVarieties: [],
      sort: 0,
      sortedVarieties: [],
      sortItems: [
        {
          id: 0,
          title: this.$t('variety.sort.seed_name_az'),
          sortBy: 'seedName',
          order: 'asc',
          crop: [CROP.SOYBEAN, CROP.WHEAT],
        },
        {
          id: 1,
          title: this.$t('variety.sort.seed_name_za'),
          sortBy: 'seedName',
          order: 'desc',
          crop: [CROP.SOYBEAN, CROP.WHEAT],
        },
        {
          id: 2,
          title: this.$t('variety.sort.GMR_small'),
          sortBy: 'GMR',
          order: 'asc',
          crop: [CROP.SOYBEAN],
        },
        {
          id: 3,
          title: this.$t('variety.sort.GMR_strong'),
          sortBy: 'GMR',
          order: 'desc',
          crop: [CROP.SOYBEAN],
        },
        {
          id: 4,
          title: this.$t('variety.sort.owner_az'),
          sortBy: 'owner',
          order: 'asc',
          crop: [CROP.SOYBEAN, CROP.WHEAT],
        },
        {
          id: 5,
          title: this.$t('variety.sort.owner_za'),
          sortBy: 'owner',
          order: 'desc',
          crop: [CROP.SOYBEAN, CROP.WHEAT],
        },
      ],
      GMR: [0, 10],
      owner: [],
      seedName: '',
      filteredVarieties: [],
      ownerList: [],
      filterReset: false,
      showing_created: true,
      timeout: null,
    }
  },

  mounted() {
    this.filteredVarieties = this.varieties
    this.applyFilter()
    this.populateOwner()
  },

  watch: {
    varieties(value, oldValue) {
      if (value != oldValue) {
        this.filteredVarieties = this.varieties
        this.applyFilter()
      }
    },
    sort() {
      this.applySort()
    },
    seedName(value, oldValue) {
      if (value.length && oldValue.length == 0) {
        logEvent(events.varieties.clickedFunction_Search_name)
      }
      if (value.length > 0) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.filter(), 1000)
      } else if (value.length === 0) {
        this.filter()
      }
    },
  },

  methods: {
    applySort() {
      const item = this.sortItems[this.sort]
      switch (item.sortBy) {
        case 'seedName':
          this.seedNameSort()
          break
        case 'GMR':
          this.GMRSort()
          break
        case 'owner':
          this.ownerSort()
          break
      }
      if (item.order === 'desc') {
        this.sortedVarieties.reverse()
      }
      this.recommendedSort()
      this.updateVarietiesList()
    },
    updateVarietiesList() {
      if (this.showing_created) {
        this.addedVarietiesSort()
        this.sortedAndFilteredVarieties = this.sortedVarieties
      } else {
        this.sortedAndFilteredVarieties = this.sortedVarieties.filter(
          (item) => item.added !== true
        )
      }
      this.$emit('updateVarietiesList', this.sortedAndFilteredVarieties)
    },
    filterUpdate(GMR, owner) {
      this.GMR = GMR
      this.owner = owner
      this.filter()
    },
    applyFilter() {
      this.filterReset = false
      this.sortedVarieties = this.filteredVarieties
      this.applySort()
    },
    resetFilter() {
      this.filterReset = true
      this.seedName = ''
      logEvent(events.varieties.clickedFunction_Filter_clean)
    },
    recommendedSort() {
      this.sortedVarieties = this.sortedVarieties.sort(
        (a, b) => b.recomendado - a.recomendado
      )
    },
    addedVarietiesSort() {
      this.sortedVarieties = this.sortedVarieties.sort(
        (a, b) => b.added - a.added
      )
    },
    seedNameSort() {
      this.sortedVarieties = this.sortedVarieties.sort((a, b) =>
        a.cultivar.localeCompare(b.cultivar)
      )
    },
    ownerSort() {
      this.sortedVarieties = this.sortedVarieties.sort((a, b) =>
        a.asset_owner_name.localeCompare(b.asset_owner_name)
      )
    },
    GMRSort() {
      this.sortedVarieties = this.sortedVarieties.sort(
        (a, b) => a.grupo - b.grupo
      )
    },
    filter() {
      const name = this.prepareString(this.seedName)
      const min = this.GMR[0]
      const max = this.GMR[1]
      this.filteredVarieties = this.varieties.filter((item) => {
        if (this.isSoybean) {
          if (this.owner.length) {
            return (
              this.prepareString(item.cultivar).includes(name) &&
              item.grupo >= min &&
              item.grupo <= max &&
              this.owner.includes(item.asset_owner_name)
            )
          } else {
            return (
              this.prepareString(item.cultivar).includes(name) &&
              item.grupo >= min &&
              item.grupo <= max
            )
          }
        } else if (this.isWheat) {
          if (this.owner.length) {
            return (
              this.prepareString(item.cultivar).includes(name) &&
              this.owner.includes(item.asset_owner_name)
            )
          } else {
            return this.prepareString(item.cultivar).includes(name)
          }
        }
      })
      this.applyFilter()
    },
    populateOwner() {
      this.varieties.forEach((item) => {
        if (!this.ownerList.includes(item.asset_owner_name)) {
          this.ownerList.push(item.asset_owner_name)
        }
      })
      this.ownerList = this.ownerList.sort((a, b) => a.localeCompare(b))
    },
    prepareString(string) {
      return removeSpecialCharacters(string.toUpperCase())
    },
    hideCreated() {
      this.showing_created = false
      this.updateVarietiesList()
    },
    showCreated() {
      this.showing_created = true
      this.updateVarietiesList()
    },
  },

  computed: {
    ...mapGetters('crops', ['currentCrop', 'isSoybean', 'isWheat']),

    activeFilter() {
      return (
        this.seedName.length > 0 || this.owner.length || this.GMRActiveFilter
      )
    },
    GMRActiveFilter() {
      return this.GMR[0] != 0 || this.GMR[1] != 10
    },
  },
}
</script>

<template>
  <div>
    <div class="header">
      <div style="width: 100%">
        <input
          v-model="seedName"
          :placeholder="$t('variety.seed_name')"
          class="name"
        />
        <v-icon size="18" color="#5B6459" class="inside">mdi-magnify</v-icon>
      </div>
      <div>
        <select v-model="sort" class="sort">
          <option
            v-for="item in sortItems.filter((item) =>
              item.crop.includes(currentCrop.id)
            )"
            :value="item.id"
            :key="item.id"
          >
            {{ item.title }}
          </option>
        </select>
        <v-icon size="18" color="#5B6459" class="inside"
          >mdi-chevron-down</v-icon
        >
      </div>
      <filter-modal
        :owner-list="ownerList"
        :reset="filterReset"
        @filterUpdate="filterUpdate"
      ></filter-modal>
    </div>
    <div class="active-filter" v-if="activeFilter">
      <div>
        <p>{{ $t('results_for') }}</p>
        <div class="d-inline" v-if="seedName.length">
          <span>&nbsp;"{{ seedName }}"</span>
        </div>
        <div class="d-inline-block" v-if="GMRActiveFilter">
          <span v-if="seedName.length">,</span>
          <b>&nbsp;GMR&nbsp;</b>
          <span>"{{ $t('_to_', GMR) }}"</span>
        </div>
        <div class="d-inline" v-if="owner.length">
          <span v-if="seedName.length || GMRActiveFilter">,</span>
          <b class="uppercase">&nbsp;{{ $t('variety.owner') }}</b>
          <div v-for="(company, idx) in owner" :key="company" class="d-inline">
            <span v-if="owner.length > 1 && idx != 0">,</span>
            <span>&nbsp;"{{ company }}"</span>
          </div>
        </div>
      </div>
      <div>
        <button class="resetFilter" @click="resetFilter">
          {{ $t('clean_filter') }}
          <v-icon size="18" color="#AAB2A9">mdi-close</v-icon>
        </button>
      </div>
    </div>
    <div class="active-filter" v-else>
      <div>
        <p>{{ $t('variety.list') }}</p>
      </div>
      <div>
        <button class="hideCreated" @click="hideCreated" v-if="showing_created">
          {{ $t('variety.hide_created') }}
          <v-icon size="18" color="#AAB2A9">mdi-eye-off</v-icon>
        </button>
        <button class="hideCreated" @click="showCreated" v-else>
          {{ $t('variety.show_created') }}
          <v-icon size="18" color="#AAB2A9">mdi-eye</v-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.name,
.sort {
  width: 100%;
  background-color: #fff;
  border: 1px solid #e6e9e6;
  border-radius: 4px;
  padding: 6px 14px;
  color: #0e180c;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 14px;
  height: 40px;
}

.header {
  display: flex;
  gap: 6px;
  align-items: center;
  width: CALC(100% - 25px);
  margin-bottom: 12px;
}

.sort {
  width: 180px;
}

.col {
  padding: 10px 12px;
}

.v-icon.inside::before {
  position: absolute;
  top: -10px;
  left: -30px;
}
.active-filter {
  font-family: 'Rubik';
  font-size: 16px;
  color: #1a2b46;
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  padding: 16px 0px;
}

.active-filter > div {
  display: inline-block;
}

.active-filter p,
span {
  font-family: 'Source Sans Pro';

  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #aab2a9;
}
.uppercase:first-letter {
  text-transform: uppercase;
}

.resetFilter,
.hideCreated {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 14px;
  color: #1a2b46;
  width: 100px;
}

.resetFilter {
  width: 100px;
}

.hideCreated {
  width: 150px;
}

@media (max-width: 960px) {
  .header {
    width: 100%;
  }
  .sort {
    width: 140px;
  }
  .active-filter {
    margin-right: 0;
  }
}
</style>
